























































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import router from '@/router'
import {
  PaginationOptions,
  parseQuery,
  usePagination,
} from '@/composables/pagination'
import Pagination from '@/components/base/Pagination.vue'
import { QueryParams } from '@/store/api-plateform-utils'
import BaseMaterialCard from '@/components/base/MaterialCard.vue'
import { UserGroup } from '@/api/interfaces/userGroup'
import { TrainingSession } from '@/api/interfaces/trainingsession'

export default defineComponent({
  setup (props, ctx) {
    const query = {
      ...router.app.$route.query,
    }
    const totalItems = () => {
      return ctx.root.$store.state.apiLog.totalItems
    }
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      }: PaginationOptions = usePagination(
    totalItems,
    parseQuery(query),
  ) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
    }
  },
  name: 'ListLogger',
  components: {
    Pagination,
    BaseMaterialCard,
  },
  props: {
    logisticSession: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      headers: [
        {
          text: this.$t('training_session.logger.headers.createAt'),
          align: 'center',
          value: 'createdAt',
        },
        {
          text: this.$t('training_session.logger.headers.last_name'),
          align: 'start',
          value: 'userLastName',
        },
        {
          text: this.$t('training_session.logger.headers.first_name'),
          align: 'start',
          value: 'userFirstName',
        },
        {
          text: this.$t('training_session.logger.headers.role'),
          align: 'start',
          value: 'userGroups',
        },
        {
          text: this.$t('training_session.logger.headers.section'),
          align: 'start',
          value: 'section',
        },
        {
          text: this.$t('training_session.logger.headers.content'),
          align: 'start',
          value: 'modifiedContent',
        },
      ],
      sessionAttributes: {
        technicalPreparation: this.$t('training_session.logger.technical_preparation'),
        trainingType: this.$t('training_session.logger.training_type'),
        trainingLocation: this.$t('training_session.logger.training_location'),
        capacity: this.$t('training_session.logger.capacity'),
        commentary: this.$t('training_session.logger.commentary'),
        startAt: this.$t('training_session.logger.start_at'),
        endAt: this.$t('training_session.logger.end_at'),
        nameHousing: this.$t('training_session.logger.name_housing'),
        addressHousing: this.$t('training_session.logger.address_housing'),
        zipCodeHousing: this.$t('training_session.logger.zip_code_housing'),
        cityHousing: this.$t('training_session.logger.city_housing'),
        priceHousing: this.$t('training_session.logger.price_housing'),
        commentaryMaterial: this.$t(
          'training_session.logger.commentary_material',
        ),
        status: this.$t('training_session.logger.status'),
        infoHousing: this.$t('training_session.logger.info_housing'),
        caterer: this.$t(
          'training_session.logger.caterer',
        ),
        vehicles: this.$t('training_session.logger.vehicles'),
        teachingTools: this.$t('training_session.logger.teaching_tools'),
        needTrainings: this.$t('training_session.logger.need_trainings'),
        trainers: this.$t('training_session.logger.trainers'),
        trainingSessionDocuments: this.$t(
          'training_session.logger.training_session_documents',
        ),
        emailSessionDocuments: this.$t(
          'training_session.logger.email_session_documents',
        ),
        goalNumberTrainees: this.$t('training_session.logger.goal_number_trainees'),
        companiesCosts: this.$t('training_session.logger.companies_costs'),
        logisticArea: this.$t('training_session.logger.logisticArea'),
        trainingOfficer: this.$t('training_session.logger.trainingOfficer'),
        commercials: this.$t('training_session.logger.commercials'),
        supplierRelationshipOfficer: this.$t('training_session.logger.supplier_relationship_officer'),
        secondaryTrainingOfficers: this.$t('training_session.logger.secondary_training_officers'),
        commentaryHousing: this.$t('training_session.logger.commentary_housing'),
        commentaryVehicle: this.$t('training_session.logger.commentary_vehicle'),
        commentaryCaterer: this.$t('training_session.logger.commentary_caterer'),
        infoVehicle: this.$t('training_session.logger.info_vehicle'),
        commentaryTrainingLocation: this.$t('training_session.logger.commentary_training_location'),
        commentarySupplierRelationshipOfficer: this.$t('training_session.logger.commentary_supplier_relationship_officer'),
      },
    }
  },
  created () {
    this.load(this.baseRequest)
  },
  watch: {
    paginationOptions: {
      handler () {
        this.loadData()
      },
      deep: true,
    },
    logisticSession: {
      handler (newValue, oldValue) {
        if (oldValue === null || newValue.id !== oldValue.id) {
          this.loadData()
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState('apiLog', {
      list: 'list',
      loading: 'loading',
    }),
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        entityIri: this.irisLogisticAndTrainingSessions,
        'exists[userApplication]': true,
        ...this.paginationRequestParams,
      }
      return request as QueryParams
    },
    irisLogisticAndTrainingSessions (): Array<string> {
      const iris: Array<string> = [this.logisticSession['@id']]
      this.logisticSession.trainingSessions.forEach((trainingSession: TrainingSession) => {
        if (trainingSession['@id']) {
          iris.push(trainingSession['@id'])
        }
      })
      return iris
    },
  },
  methods: {
    ...mapActions('apiLog', {
      load: 'load',
    }),
    ...mapActions('user', {
      loadUser: 'findOneById',
    }),
    loadData () {
      this.load(this.baseRequest)
    },
    translateContent (fields: Array<string>) {
      fields = Array.isArray(fields) ? fields : Object.values(fields)
      return fields
        .map((field: string) => (this.sessionAttributes as any)[field])
        .filter((field: string) => field !== undefined)
    },
    listUserGroups (userGroups: Array<UserGroup>) {
      const names: Array<string> = []

      userGroups.map((userGroup: UserGroup) => {
        const name = userGroup.name as string
        names.push(name.charAt(0).toUpperCase() + name.slice(1))
      })

      return names
    },
    showSection (entityIri: string): string {
      let section = ''
      if (entityIri.startsWith('/api/logistic_sessions/')) {
        section = this.$t('training_session.logger.section.logistic_session') as string
      } else if (entityIri.startsWith('/api/training_sessions/')) {
        section = this.$t('training_session.logger.section.training_session') as string
        const sessionIndex = this.logisticSession.trainingSessions.reduce(
          (accumulatorIndex: number, trainingSession: TrainingSession, currentIndex: number) => {
            return trainingSession['@id'] === entityIri ? currentIndex : accumulatorIndex
          }, 0)
        section += ' #' + (sessionIndex + 1)
      }
      return section
    },
  },
})
